import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-information-box',
  templateUrl: './information-box.component.html',
  standalone: true,
  imports: [CommonModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class InformationBoxComponent {
  @Input() title = '';
  @Input() body = '';
  @Input() showDismissButton = false;
  @Input() button!: TemplateRef<unknown>;
  @Input() colour = 'yellow';

  public visible = true;
}
