import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './features/landing-pages/error/error.component';
import { AuthenticationWrapperGuard } from './shared/guards/auth/authentication-wrapper.guard';
import { MaintenanceGuard } from './shared/guards/maintenance/maintenance.guard';
import { MaintenanceComponent } from './features/landing-pages/maintenance/maintenance.component';
import { EventRequestInboxComponent } from './shared/components/event-request-inbox/event-request-inbox.component';

const routes: Routes = [
  {
    path: 'recommendations',
    loadChildren: () => import('./features/recommendations/recommendations-routes').then(m => m.routes),
    canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
  },
  {
    path: 'draft-confirmation',
    loadChildren: () => import('./features/draft-confirmation/draft-confirmation-routes').then(m => m.routes),
    canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
  },
  {
    path: '',
    loadComponent: () => import('./ui/containers/layout/layout.component').then(mod => mod.LayoutComponent),
    children: [
      {
        path: 'event-request',
        loadChildren: () => import('./features/event-request/event.module').then(m => m.EventModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      {
        path: 'events',
        loadChildren: () => import('./features/events/events.module').then(m => m.EventsModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      {
        path: 'quote',
        loadChildren: () => import('./features/quotes/quotes.module').then(m => m.QuotesModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      {
        path: 'quotes',
        loadChildren: () => import('./features/quotes/quotes.module').then(m => m.QuotesModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      {
        path: 'bookings',
        loadChildren: () => import('./features/quotes/quotes.module').then(m => m.QuotesModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      {
        path: 'listing',
        loadChildren: () => import('./features/listings/listing.module').then(m => m.ListingModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      {
        path: 'invites',
        loadChildren: () => import('./features/invites/invites.module').then(m => m.InvitesModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      {
        path: 'new-request',
        loadChildren: () => import('./features/new-request/new-request.module').then(m => m.NewEventModule),
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      },
      { path: 'maintenance', component: MaintenanceComponent },
      { path: 'error', component: ErrorComponent },
      {
        path: '**',
        component: EventRequestInboxComponent,
        canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
      }
      // Add other routes that need the layout here
    ]
  },
  {
    path: '**',
    component: EventRequestInboxComponent,
    canActivate: [MaintenanceGuard, AuthenticationWrapperGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
