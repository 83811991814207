<div
  *ngIf="statusConfig"
  class="status-base tw-flex tw-h-6 tw-w-fit tw-items-center tw-justify-center tw-gap-1.5 xl:tw-h-7 xl:tw-gap-2"
  [ngClass]="statusConfig?.class">
  <div class="icon-circle tw-flex tw-size-4 tw-items-center tw-justify-center tw-align-middle xl:tw-size-5" *ngIf="statusConfig?.icon">
    <ate-icon [ngClass]="statusConfig.iconSize" [name]="statusConfig?.icon"></ate-icon>
  </div>
  <div class="icon-circle tw-flex tw-size-3 tw-items-center tw-justify-center tw-align-middle" *ngIf="!statusConfig.icon"></div>
  <p class="request-item-text tw-mb-0 tw-font-semibold">{{ statusConfig?.name }}</p>
</div>
