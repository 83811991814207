import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { first } from 'rxjs';
import { InformationBoxComponent } from '../information-box/information-box.component';
import { EventsService } from '../../services/events/events.service';
import { Router } from '@angular/router';
import { FlashMessageService } from '../../services/flash-message/flash-message.service';
import { FlashMessageType } from '../../enums/flash-message-type.enum';

@Component({
  selector: 'app-draft-request-information-box',
  templateUrl: './draft-request-information-box.component.html',
  standalone: true,
  imports: [CommonModule, InformationBoxComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class DraftRequestInformationBoxComponent {
  _isLoading: boolean;

  @Input() eventUuid = '';
  @Input() eventRequestUuid = '';

  constructor(
    private _router: Router,
    private _eventsService: EventsService,
    private _flashMessageService: FlashMessageService
  ) {}

  getQuotesNow() {
    this._isLoading = true;
    this._eventsService
      .openEvent(this.eventUuid)
      .pipe(first())
      .subscribe({
        next: () => {
          this._router.navigate(['/invites/', this.eventUuid, this.eventRequestUuid]);
          this._isLoading = false;
        },
        error: () => {
          this._isLoading = false;
          this._flashMessageService.show({
            flashMessageType: FlashMessageType.Error,
            message: `Failed to open event request: ${this.eventRequestUuid}`
          });
        }
      });
  }
}
