import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component } from '@angular/core';
import { InformationBoxComponent } from '../information-box/information-box.component';

@Component({
  selector: 'app-multiple-events-information-box',
  templateUrl: './multiple-events-information-box.component.html',
  standalone: true,
  imports: [CommonModule, InformationBoxComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MultipleEventsInformationBoxComponent {}
