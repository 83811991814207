import { Injectable } from '@angular/core';
import { Observable, filter, map } from 'rxjs';
import { EventRequestSingleType } from '../event-request/event-request.type';
import { Store } from '@ngrx/store';
import { EventRequestTypeEnum } from '../event-request/event-request-type.enum';
import { EventRequestStatusEnum } from '../event-request/event-request-status.enum';
import { selectEvent } from '../../../store/selectors/event.selectors';
import { EventState } from '../../../store/reducers/event.reducer';

@Injectable()
export class EventRequestOverviewService {
  constructor(private _store: Store<EventState>) {}

  getEvent$(): Observable<EventRequestSingleType> {
    return this._store.select(selectEvent.getEvent).pipe(
      map(result => result),
      filter(eventRequestSingleType => eventRequestSingleType?.event !== undefined && eventRequestSingleType?.event !== null)
    );
  }

  getIconConfig(eventType: string | EventRequestTypeEnum) {
    const iconConfig: { [key in EventRequestTypeEnum]: { name: string; colour: string } } = {
      [EventRequestTypeEnum.ANNIVERSARY]: {
        name: 'ate-anniversary-v2',
        colour: 'tw-text-red-100'
      },
      [EventRequestTypeEnum.BIRTHDAY_PARTY]: {
        name: 'ate-birthday-cake',
        colour: 'tw-text-lime-100'
      },
      [EventRequestTypeEnum.CHARITY_EVENT]: {
        name: 'ate-charity-event-v2',
        colour: 'tw-text-sky-100'
      },
      [EventRequestTypeEnum.CHILDRENS_PARTY]: {
        name: 'ate-childrens-party-v2',
        colour: 'tw-text-emerald-100'
      },
      [EventRequestTypeEnum.CHRISTENING]: {
        name: 'ate-ceremony-v2',
        colour: 'tw-text-amber-100'
      },
      [EventRequestTypeEnum.CORPORATE_EVENT]: {
        name: 'ate-corporate-event-v2',
        colour: 'tw-text-blue-100'
      },
      [EventRequestTypeEnum.FESTIVAL]: {
        name: 'ate-festival-v2',
        colour: 'tw-text-purple-100'
      },
      [EventRequestTypeEnum.HEN_PARTY]: {
        name: 'ate-hen-party-v2',
        colour: 'tw-text-pink-100'
      },
      [EventRequestTypeEnum.STAG_PARTY]: {
        name: 'ate-stag-party-v2',
        colour: 'tw-text-cyan-100'
      },
      [EventRequestTypeEnum.OTHER_PARTY]: {
        name: 'ate-celebrate-v3',
        colour: 'tw-text-fuchsia-100'
      },
      [EventRequestTypeEnum.UNSPECIFIED_EVENT]: {
        name: 'ate-unspecified-v3',
        colour: 'tw-text-yellow-100'
      },
      [EventRequestTypeEnum.EDUCATION_EVENT]: {
        name: 'ate-learning-v3',
        colour: 'tw-text-green-100'
      },
      [EventRequestTypeEnum.WEDDING]: {
        name: 'ate-wedding-v2',
        colour: 'tw-text-amber-100'
      }
    };

    return iconConfig[eventType];
  }

  getEventIconConfig(eventType: string | EventRequestTypeEnum) {
    const iconConfig: { [key in EventRequestTypeEnum]: { name: string; icon_class: string } } = {
      [EventRequestTypeEnum.ANNIVERSARY]: {
        name: 'ate-anniversary-v2',
        icon_class: 'icon_anniversary'
      },
      [EventRequestTypeEnum.BIRTHDAY_PARTY]: {
        name: 'ate-birthday-cake',
        icon_class: 'icon_birthday_party'
      },
      [EventRequestTypeEnum.CHARITY_EVENT]: {
        name: 'ate-charity-event-v2',
        icon_class: 'icon_charity_event'
      },
      [EventRequestTypeEnum.CHILDRENS_PARTY]: {
        name: 'ate-childrens-party-v2',
        icon_class: 'icon_childrens_party'
      },
      [EventRequestTypeEnum.CHRISTENING]: {
        name: 'ate-ceremony-v2',
        icon_class: 'icon_christening'
      },
      [EventRequestTypeEnum.CORPORATE_EVENT]: {
        name: 'ate-corporate-event-v2',
        icon_class: 'icon_corporate_event'
      },
      [EventRequestTypeEnum.FESTIVAL]: {
        name: 'ate-festival-v2',
        icon_class: 'icon_festival'
      },
      [EventRequestTypeEnum.HEN_PARTY]: {
        name: 'ate-hen-party-v2',
        icon_class: 'icon_hen_party'
      },
      [EventRequestTypeEnum.STAG_PARTY]: {
        name: 'ate-stag-party-v2',
        icon_class: 'icon_stag_party'
      },
      [EventRequestTypeEnum.OTHER_PARTY]: {
        name: 'ate-celebrate-v3',
        icon_class: 'icon_other_party'
      },
      [EventRequestTypeEnum.UNSPECIFIED_EVENT]: {
        name: 'ate-unspecified-v3',
        icon_class: 'icon_unspecified_event'
      },
      [EventRequestTypeEnum.EDUCATION_EVENT]: {
        name: 'ate-learning-v3',
        icon_class: 'icon_education_event'
      },
      [EventRequestTypeEnum.WEDDING]: {
        name: 'ate-wedding-v2',
        icon_class: 'icon_wedding'
      }
    };

    return iconConfig[eventType];
  }

  /**
   * Returns the HTML config for a given event request status.
   *
   * @param status The event status
   */
  getInformationConfig(status: EventRequestStatusEnum): { title: string; body: string; showNewRequestButton?: boolean } {
    switch (status) {
      case EventRequestStatusEnum.CLOSED_BEFORE_APPROVAL:
        return {
          title: 'Your request has been closed',
          body:
            `Your service request has been closed and you will no longer receive quotes. ` +
            `<b>Click below to open a new request for your upcoming event.</b>`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.DENIED_FLOOD:
        return {
          title: 'Your request has been denied so cannot be sent at this time',
          body:
            `Please try again in 24 hours. Your request can be denied for a variety of reasons such as our system has detected it is a duplicate of a previous event request you submitted or you have already maximised your request limit for the day.` +
            `<br /><br />` +
            `For help submitting a new request, please contact <a class="tw-text-slate-800 tw-underline">support@addtoevent.co.uk</a> with information about your event and denied request.`
        };
      case EventRequestStatusEnum.DENIED_MANUAL:
      case EventRequestStatusEnum.DENIED_EMAILS:
      case EventRequestStatusEnum.DENIED_DUPLICATE:
        return {
          title: 'Your request has been denied so cannot be sent at this time',
          body:
            `Your request can be denied for a variety of reasons such as our system has detected it is a duplicate of a previous event request you submitted or you have already maximised your request limit for the day.` +
            `<br /><br />` +
            `For help submitting a new request, please contact <a class="tw-text-slate-800 tw-underline">support@addtoevent.co.uk</a> with information about your event and denied request.`
        };
      case EventRequestStatusEnum.CLOSED_NO_MATCHES:
        return {
          title: 'We’re sorry, at the moment no suppliers are available to fulfil your request',
          body:
            `Sometimes this happens because suppliers don’t have enough information. Try adding more details like the number of guests or specific location, changing the date/location, or submitting a new request for a different category (e.g. if you submitted a request for ‘horsebox bars’, try a more generic search like ‘mobile bars’ to get matched with more available suppliers).` +
            `<br/><br/>` +
            `<b>Click below to open a new request for your upcoming event.</b>`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.CLOSED_EXPIRED_NO_QUOTES:
        return {
          title: 'We’re sorry, at the moment no suppliers are available to fulfil your request',
          body:
            `Sometimes this happens because suppliers don’t have enough information. Try adding more details like the number of guests or specific location, changing the date/location, or submitting a new request for a different category (e.g. if you submitted a request for ‘horsebox bars’, try a more generic search like ‘mobile bars’ to get matched with more available suppliers).` +
            `<br/><br/>` +
            `<b>Click below to open a new request for your upcoming event.</b>`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.EXPIRED_ALL_DECLINED_QUOTES:
        return {
          title: 'Your request will no longer receive new quotes',
          body: `More than 7 days have passed since you submitted your request so we have closed the request because it is unlikely any more suppliers will quote after this length of time. We are sorry that none of the quotes you received were suitable for your event.`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.CLOSED_PASSED:
        return {
          title: 'You have had your event',
          body:
            `The date you listed for your event has passed so your request has been closed. We hope your event went off without a hitch! ` +
            `<b>Start planning your next one with a range of unique suppliers by submitting a new request.</b>`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.CLOSED_REASON_NO_QUOTES:
        return {
          title: 'You have closed this request',
          body:
            `You have chosen to close your service request and will no longer receive quotes. ` +
            `<b>Need something different? Find more services for your upcoming event or start planning your next one by submitting a new request.</b>`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.EXPIRED_ACTIVE_QUOTES:
        return {
          title: 'Your request will no longer receive new quotes',
          body:
            `More than 7 days have passed since you submitted your request and it is unlikely any more suppliers will quote after this length of time. ` +
            `<b>Be sure to review your active quotes and respond to the interested suppliers</b>`
        };
      case EventRequestStatusEnum.CLOSED_REASON_OPEN_QUOTES:
        return {
          title: 'You have closed this request',
          body:
            `You have chosen to close your service request and will no longer receive quotes. ` +
            `<b>Need something different? Find more services for your upcoming event or start planning your next one by submitting a new request.</b>`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.CLOSED_REASON_ALL_DECLINED_QUOTES:
        return {
          title: 'You have closed this request',
          body:
            `You have chosen to close your service request and will no longer receive quotes. ` +
            `<b>Need something different? Find more services for your upcoming event or start planning your next one by submitting a new request.</b>`,
          showNewRequestButton: true
        };
      case EventRequestStatusEnum.DRAFT_PASSED:
        return {
          title: 'You have had your event',
          body:
            `The date you listed for your event has passed so your request has been closed. We hope your event went off without a hitch! ` +
            `<b>Start planning your next one with a range of unique suppliers by submitting a new request.</b>`,
          showNewRequestButton: true
        };
    }
  }
}
