<ng-template #button>
  <div class="tw-flex tw-items-start tw-self-stretch">
    <a
      href="https://organiser-support.addtoevent.co.uk/en/articles/10086302-changing-the-parent-event-for-a-request"
      target="_blank"
      class="btn-action btn-action-sm btn-primary tw-flex-[1_0_0]">
      Find Out How
    </a>
  </div>
</ng-template>

<app-information-box [showDismissButton]="true" [button]="button">
  <p class="tw-mb-0 tw-text-sm lg:tw-text-base">It looks like you have multiple events that are the same. We can help you organise them</p>
</app-information-box>
